.custom-table .svg-icon {
    color: #a1a5b7;
    line-height: 1;
}

.custom-table .svg-icon {
    color: #a1a5b7;
    line-height: 1;
}

.custom-table .svg-icon.svg-icon-3 svg {
    height: 1.55rem !important;
    width: 1.55rem !important;
}

.modal {
    z-index: 10000;
}

tr:hover {
    background-color: #efefef;
}