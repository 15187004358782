.status-dropdown button{
    font-size: 10px !important;
    padding: 3px !important;
    border-radius: 5px;
}

.status-dropdown .dropdown-item-wrapper button{
    font-size: 10px !important;
    padding: 3px !important;
    border-radius: 5px;
    color: white !important;
}

.min-h {
    height: fit-content !important;
}

.min-h-200 {
    min-height: 197px;
}