.swal2-height-auto {
    height: 100% !important;
}

#react-select-3-listbox {
    z-index: 9999 !important;
}

.css-13cymwt-control {
    border-radius: 5px;
}



.user-name {
    color: #fef5eb;
}

.stickyLeft {
    position: sticky;
    top: 0;
    left: -100;
    z-index: 9999;
}

#note:focus {
    outline: none;
}

// estilo iconos
.icon-large {
    font-size: 1.7rem;
    cursor: pointer;
}

.icon-large:hover {
    color: #414141 !important;
}

// estilo check de alérgenos
.checkboxList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.checkbox-apple {
    display: flex;
    align-items: center;
    position: relative;
    width: fit-content;
    height: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-apple label {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background: linear-gradient(to bottom, #cacaca, #e6e6e6);
    cursor: pointer;
    transition: all 0.3s ease;
}

.checkbox-apple label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
}

.checkbox-apple input[type="checkbox"]:checked+label {
    background: linear-gradient(to top, #f5d0a9, #f59c39);
}

.checkbox-apple input[type="checkbox"]:checked+label:after {
    transform: translateX(25px);
}

.checkbox-apple label:hover {
    background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
}

.checkbox-apple label:hover:after {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.yep {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 25px;
}

.fs-7 {
    font-size: 11.25px;
}

.fs-8 {
    font-size: 11px;
}

.table-responsive {
    overflow-x: auto;
    overflow-y: visible;
}

.dropdown-menu {
    z-index: 9999 !important;
    //position: relative !important;
}

.btn-blue {
    background-color: #CBD5E0 !important;
    border-color: #c9d5e2 !important;
}



/********************** Estilos del acordeón **********************/

.accordion-button {
    border-radius: 10px !important;
    background-color: #f9e6ec;
}

.accordion-button:not(.collapsed) {
    background-color: #f9e6ec;
    box-shadow: 0 0 0 2px #d04636;
    color: black;
}

.accordion-button:hover {
    box-shadow: 0 0 0 2px #d04636;
}

/************************ Estilos del label ************************/

.labelStyle {
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #6c757d;
}

.labelRequired {
    color: #e8673d;
}


/**************** Estilos del modal de confirmación ****************/

.swal2-icon.swal2-warning {
    border-color: #CE6B52 !important;
}


/************** Estilos del botón dropdown de status **************/

.Dropdown-control {
    border: none !important;
    padding: 8px 10px !important;
    background-color: white !important;
}

.dropdown-status {
    width: 110px !important;
}


/**************** Estilos del selector por defecto ****************/

.form-select option:hover {
    background-color: #d04636 !important;
    color: white !important;
}


/******************* Estilos del editor de HTML *******************/

.rdw-editor-main {
    border: 1px solid lightgray;
}


/************************ Estilos generales ************************/

.redLight {
    color: #e8673d;
}


/***************** Estilos de circulos de usuarios *****************/

.user-circles-container {
    width: 60%;
    position: relative;
    height: auto;
    display: flex;
    flex-direction: row;
}

.user-image {
    width: fit-content;
    transition: transform 0.3s, z-index 0.3s;
}

.user-circle {
    margin-left: -10px;
    cursor: pointer;
}

.user-circle:hover {
    transform: scale(1.2);
    z-index: 1;
}


/******************* Estilos del CardTab ***********************/

.docTypesPage {
    padding: 0px;
}

.docTypesPage .card-body {
    padding: 0px;
}

.cardTabDocTypes .card {
    box-shadow: none;
}

.cardTabDocTypes .card-header.card-header-border-1 {
    border-bottom-width: 0;
    padding: 0px;
    margin-top: -15px;
    margin-bottom: -5px;
}

.card-header {
    display: block; // para juntar aún más los botones
}

.cardTabDocTypes .custom-table {
    padding: 0px !important;
}

.btnUploadDocument {
    background-color: #f1bcc4 !important;
    border-color: #f1bcc4 !important;
}


/******************* Estilos de los enlaces (a) ***********************/

a {
    color: #d04636;
    text-decoration: none;
}


/**************** Estilo del dropdown del calendario ********************/

.alignFixed {
    position: absolute !important;
}


/*********** Estilo de los elementos del gestor de documentos ***********/

.btnHoverColor:hover {
    background-color: rgba(208, 70, 54, 0.5) !important;
}