//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.aside .navigation-text, .aside .navigation-link {
	color: white !important;
}

.footer {
	z-index: 0;
	align-self: end;
}

.avatar-contain{
	object-fit: contain !important;
}

.chat-message-reply {
	background-color: var(--bs-primary);
}