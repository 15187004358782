.calendar-container {
    padding: 15px 0;
    overflow: hidden;
    /* Esconde el scroll del contenedor principal */
    display: flex;
    justify-content: center;
}

.calendar-content {
    overflow: auto;
    /* Muestra el scroll del otro contenedor */
    white-space: nowrap;
    max-width: 100%;
    max-height: 80vh;
    border: 1px solid #eaeaea;
    position: relative;
}

.calendar-content table {
    width: 100%;
    border-collapse: collapse;
}

.calendar-content th,
.calendar-content td {
    border: 1px solid #eaeaea;
    text-align: center;
    padding: 8px 3px;
    min-width: 75px;
    font-size: 0.9rem;
}

.calendar-content td.resources {
    text-align: left;
}

.calendar-content thead {
    background-color: #f4f4f4;
}

.today {
    background-color: rgb(253, 253, 171);
    border: 2px solid #fbc02d;
}

.start-balance {
    background-color: #c186f8;
}

.fixed-column {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 2;
}

.level-0 {
    background-color: #ffffff;
    padding-left: 10px;
}

.level-1 {
    background-color: #f9f9f9;
    padding-left: 30px;
}

.level-2 {
    background-color: #f2f2f2;
    padding-left: 50px;
}

.level-3 {
    background-color: #ebebeb;
    padding-left: 70px;
}

.level-4 {
    background-color: #e4e4e4;
    padding-left: 90px;
}

.level-5 {
    background-color: #dddddd;
    padding-left: 110px;
}

.level-6 {
    background-color: #d6d6d6;
    padding-left: 130px;
}

.level-7 {
    background-color: #cfcfcf;
    padding-left: 150px;
}

.level-8 {
    background-color: #c8c8c8;
    padding-left: 170px;
}

.level-9 {
    background-color: #c1c1c1;
    padding-left: 190px;
}

.level-10 {
    background-color: #bababa;
    padding-left: 210px;
}

.events {
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}

.event-paid {
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.title-paid {
    color: #87a78d;
}

.event-pending {
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.title-pending {
    color: #ddc986;
}

.totals-row {
    background-color: #fff3f3;
}

.total-pending {
    background-color: #fdfbf1;
}

.total-paid {
    background-color: #f1f8e9;
}

.daily-balance {
    background-color: #e9f4ff;
}

.note-input {
    width: 100%;
    padding: 5px;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    margin: 0px;
    text-align: center;
}